.promocode__body {

    max-width: 480px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    margin: auto;
    overflow: auto;
    position: relative;
    padding: 70px 15px 140px 15px;

}

.tbl_action_icon {
    width: 32px;
    cursor: pointer;
}

.modal-backdrop {
    z-index: 0;
}

.promocode {
    background: url("../../../public/assets/image/promocode__bg.png") no-repeat center;
    background-size: 100% 100%;
}

.promocode p {
    font-size: 18px;
    font-family: "Poppins-Medium";
    color: #fff;
}

.inputbox {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 5px;
}

.inputtx::after {
    content: "";
    position: absolute;
    height: 80%;
    width: 1px;
    background: #979FAC;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
}

.inputtx {
    position: relative;
}

.inputbox select {
    color: #002D74 !important;
}

.inputbox select,
.inputbox input {
    background: #fff;
    font-size: 18px;
    position: relative;
    font-family: "Poppins-Reg";
    line-height: 0;
    min-height: 100%;

}

.intl-tel-input .country-list {
    width: 100%;
}

.iti-mobile .intl-tel-input.iti-container {
    left: 0 !important;
    right: 0 !important;
}

.intl-tel-input {
    width: 100%;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 5px;
}

.inputbox .form-control {
    line-height: 1;
    padding: 5px 14px 5px;
    min-height: 50px;
}

.inputbox .selected-flag::after {
    background: #979fac;
    content: "";
    height: 80%;
    right: -2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
}

.inputbox .form-control::placeholder {
    font-size: 18px;
    line-height: 0;
    min-height: 100%;
}



.inputbox .form-control:focus {
    box-shadow: none !important;
    font-size: 18px;
    border: none !important;
    line-height: 0 !important;
}

.heading {
    font-size: 30px;
    font-family: "Poppins-Medium";
    color: #fff;
}

.logop {
    width: 100px;
    margin: auto;
}

.btn.btn--blue {
    background: #002D74;
    color: #fff;
    font-size: 18px;
    padding: 11px 10px;
    border-radius: 25px;
    font-family: "Poppins-Medium";
}

.modalpr .btn-close {
    display: none;
}

.modalpr .modal-close {
    position: relative;
    top: 10px;
    width: 16px;
    cursor: pointer;
}

.btn-info {
    box-shadow: none !important;
}


.custom-select-box {
    position: relative;
    width: 100%;

}

.addCursor {
    cursor: pointer;
}

.select-box-selected {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    background-image: url("../../assets/image/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: center right 9px;
    background-size: 14px;
}

.select-box-options {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000;
}

.select-box-option {
    padding: 10px;
    cursor: pointer;
}

.select-box-option:hover {
    background-color: #f1f1f1;
}




.tooltip-inner {
    max-width: 100% !important;
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    opacity: 1 !important;
}



.toast-container {
    transition: transform 0.5s ease-in-out !important;

}

.toast.show {
    transform: translateX(0) !important;
    background: white;
    width: 100%;
    max-width: 298px;
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 15px 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.toast-header {
    position: relative;
    margin-bottom: 8px;
}

.me-auto {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ff0000
}

.toast-header .btn-close {
    position: absolute;
    right: 0;
    top: -10px;
    border: none;
    background: none;
    color: #ff0000;
}

.toast-header .btn-close::before {
    content: '\00D7';
    /* Unicode for "×" */
    font-size: 24px;
}

.toast.hide {
    transform: translateX(100%) !important;
}

.toast-body {
    font-size: 18px;
}

.editCode {
    background-color: #fff !important;
}

.disabled-row {
    background-color: #f0f0f0; /* Grey background */
    opacity: 0.5; /* Make it look faded */
    pointer-events: none; /* Disable interactions */
  }