.pagination {
  justify-content: center;
  margin-top: 1.6rem;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;

  .page-item {
    padding: 2px 2px;
    border: 0px !important;

    .page-link {
      border: 0px !important;
      color: #333;
      font-size: 1rem !important;
      height: 27px;
      width: 27px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .pagination-arrow {
        stroke: #7898AE;
      }

      &:disabled {
        cursor: not-allowed;

        .pagination-arrow {
          stroke: #ddd;
        }
      }

      &:hover {
        color: #fff !important;
        background-color: #7898AE;

        .pagination-arrow {
          stroke: #ddd;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.page-link {
  border: 0px !important;
  color: #7898AE;
}

.page-link:hover svg path {
  stroke: #fff;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #7898AE;
}

.pointer {
  cursor: pointer;
}

.cuttool {
  left: -34px !important;
}

.cuttool .tooltip-inner {
  min-width: 85px;
  border: 1px solid #DEE2E6;
}

.cuttool .tooltip-inner input {
  accent-color: #7898AF;
}

.cuttool .tooltip-inner label {
  font-size: 12px;
}

.cuttool .tooltip-inner li {
  padding: 4px 0;
  border-bottom: 1px solid #DEE2E6;
  width: 100%;
  cursor: pointer;
}

.cuttool label {
  cursor: pointer;
}

.cuttool .tooltip-inner li:last-child {
  border-bottom: 0;
}