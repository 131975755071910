/*
Table Of Contents
=========================
- Default Typography
- Custom Typography
=========================
*/


/*
--------------------------
- Default Typography
--------------------------
*/

body {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}

@font-face {
  font-family: Sofia_R;
  src: url("../fonts/sofia-pro/Sofia\ Pro\ Regular\ Az.otf");
}

@font-face {
  font-family: Sofia_M;
  src: url("../fonts/sofia-pro/Sofia\ Pro\ Medium\ Az.otf");
}

@font-face {
  font-family: Sofia_SB;
  src: url("../fonts/sofia-pro/Sofia\ Pro\ Semi\ Bold\ Az.otf");
}

@font-face {
  font-family: Sofia_B;
  src: url("../fonts/sofia-pro/Sofia\ Pro\ Bold\ Az.otf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 26px;
  color: #444;
  margin-bottom: 0;
}

p img {
  margin: 0;
}

/* links */

a,
a:visited {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: 0;
  font-family: 'Poppins', sans-serif;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

p a,
p a:visited {
  line-height: inherit;
  outline: 0;
}


/* list */

ul,
ol {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0;
}

ul {
  margin: 0;
  list-style-type: none;
}

ol {
  list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
  margin-left: 0px;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0;
}

ul ul li,
ul ol li,
ol ol li,
ol ul li {
  margin-bottom: 0px;
}

button {
  cursor: pointer;
  outline: none !important;
  letter-spacing: 0;
}

/*
--------------------------
- Custom Typography
--------------------------
*/
/* blockquote */
blockquote {
  padding: 60px;
  position: relative;
  background: #853BFA;
}

blockquote:before {
  content: '\f10d';
  font-family: fontawesome;
  color: #fff;
  font-size: 32px;
  position: absolute;
  left: 16px;
  top: 46px;
}

blockquote p {
  font-size: 17px;
  color: #fff;
}

.blockquote-footer {
  color: #fff;
}




/* // stream view */




.text-center {
  text-align: center;
}

.position-relative {
  position: relative;
}

.streamView {

  /* background: url("../image/bgviewstream.png"); */
  /* background-repeat: no-repeat;
  background-size: cover; */
  /* background-position: 50%; */
  /* min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative; */
  /* font-family: "sofia-pro"; */
  /* font-family: "Sofia_R"; */
}


.stream-body {
  /* max-width: 370px; */
  width: 100%;
  padding: 65px 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
}


.streamView_heading {
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  /* font-family: "Sofia_R"; */
  /* font-family: "sofia-pro-bold"; */
  font-family: "Poppins-Medium";
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 20px;
  /* font-weight: 600; */
}

.stream_date {
  color: #002D74;
  font-family: "Sofia_M";
  background-color: #fff;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  max-width: 120px;
}



.data-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stream-data {
  width: 342px;
  color: #002D74;
  font-family: "Sofia_M";
  background-color: #fff;
  padding: 29px 40px 26px 27px;
  font-size: 15px;
  border-radius: 20px;
  line-height: 22px;
  position: relative;
  margin-bottom: 20px;
}

.stream-data:last-child {
  margin-bottom: 0px;
}

.dots-img {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9;
  cursor: pointer;
}

.stream-text {
  max-width: 350px;
  color: #2e5a75;
  border-radius: 40px;
  margin: 0 auto 23px;
  font-size: 1.05rem;
  font-family: "Poppins-Light";
  font-weight: 500;
  padding: 20px;
}

.streamImage {
  /* height: 250px; */
  background-color: #fff;
  padding: 3px;
  border-radius: 15px;
  position: relative;
  /* width: 342px; */
}

.stream_video {
  height: 250px;
  background-color: #fff;
  padding: 3px;
  border-radius: 15px;
  position: relative;
}

.streamPuzzleImage {
  height: 450px;
  background-color: #fff;
  padding: 40px 30px 20px 30px;
  border-radius: 15px;
  position: relative;
}

.streamImage .content-img {
  height: 100%;
  width: 100%;
  border-radius: 13px;
  object-fit: cover;
  object-position: center;
}

.stream-promt {
  display: flex;
  align-items: center;
  width: 100%;
  color: #002D74;
  font-family: "Sofia_M";
  background-color: #fff;
  padding: 27px;
  font-size: 15px;
  border-radius: 20px;
  line-height: 22px;
  position: relative;
  margin-bottom: 20px;
  word-break: break-all;
}

.stream-promt .video-icon {
  margin-right: 20px;
}

/* .stream-text,
.stream-text-content-child {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
  display: flex;
  justify-content: center;
  align-items: center;
} */

.playBtnImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* audio css */

.stream-audio-data {
  width: 342px;
  color: #002D74;
  /* font-family: "sofia-pro"; */
  font-family: "Sofia_R";
  background-color: #fff;
  padding: 30px 25px 40px 25px;
  font-size: 15px;
  border-radius: 20px;
  line-height: 22px;
  position: relative;
  /* margin-bottom: 20px; */
}

.audio_name {
  color: #002D74;
  /* font-family: "sofia-pro"; */
  font-size: 15px;
  font-family: "Sofia_M";
  /* font-weight: 600; */
}

.audio-player {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.audio-player .play-btn {
  margin: 0 10px;
  background: none;
  border: none;
}


.audio-player .play-btn img {
  max-width: 100%;
  height: auto;
}

.audio-player .current-time {
  position: absolute;
  left: 30px;
  bottom: -5px;
}

.audio-player .slider {
  background: #476d84;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  /* -webkit-appearance: none; */
  appearance: none;
  transition: opacity .2s;
}

.audio-player .duration {
  position: absolute;
  right: 20px;
  bottom: -5px;
}





.stream-date {
  font-size: 15px;
  color: #002D74;
  word-break: break-word;
  text-align: center;
  font-family: inherit;
}

.stream-data p {
  font-family: "Sofia_R";
  color: #002D74;
}


.stream-user {
  position: absolute;
  top: 20px;
  right: 15px;
}

.stream-info {
  max-width: 342px;
  margin-bottom: 30px;
}

.stream-info .stream-name {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.user-info {
  background-color: #2e5a75;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stream-body {
  padding: 30px 5px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
  overflow: auto;
}

.stream-body::-webkit-scrollbar {
  width: 6px;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.stream-body::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: 5px;
}

/* modal css */

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}


/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}